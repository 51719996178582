import React from 'react';
import { graphql } from 'gatsby';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import SEO from './seo';

const useStyle = makeStyles({
  root: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Album = ({
  data: {
    markdownRemark: {
      frontmatter: {
        href,
        title,
      },
    },
  },
}) => {
  const classes = useStyle();

  React.useEffect(() => {
    let isCancel = false;

    setTimeout(() => {
      if (isCancel || typeof window === 'undefined' || !href) {
        return;
      }

      window.location.href = href;
    }, 2000);

    return () => {
      isCancel = true;
    };
  }, [href]);

  return (
    <div className={classes.root}>
      <SEO title={title} />

      <a href={href}>
        <CircularProgress size={100} thickness={1} />
      </a>
    </div>
  );
};

export default Album;

export const query = graphql`
  query ($id: String) {
    markdownRemark(
      id: { eq: $id }
    ) {
      frontmatter { href title }
    }
  }
`;
